import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import API from '../config/axiosConfig';
import { 
  TextField, Button, Grid, Typography, Checkbox, FormControlLabel, Box, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import CommonLayout from '../components/CommonLayout';
import PermissionsManager from '../components/PermissionsManager';
import ChangePasswordDialog from '../components/ChangePasswordDialog';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#103C6E',
          color: 'white',
          '&:hover': {
            backgroundColor: '#0d3157',
          },
          '&:disabled': {
            backgroundColor: '#B9EDE7',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          textAlign: 'right',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(-14px, -9px) scale(0.75)',
          '&[data-shrink="false"]': {
            transform: 'translate(14px, 16px) scale(1)',
          },
        },
      },
    },
  },
});

const hospitalRoles = [
  { value: 'SENIOR_PHYSICIAN', label: 'רופא בכיר' },
  { value: 'MEDICAL_RESIDENT', label: 'מתמחה' },
  { value: 'NURSE', label: 'אחות' },
  { value: 'PARAMEDICAL', label: 'פרא-רפואי' },
  { value: 'ADMINISTRATION', label: 'אדמיניסטרציה' },
  { value: 'OTHER', label: 'אחר' },
];

function EditUser() {
  const { user: loggedInUser } = useContext(UserContext);
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    isSuperAdmin: false,
    roles: [{ hospital: '', role: 'USER' }],
    hospitalRole: ''
  });
  const location = useLocation();
  const [hospitals, setHospitals] = useState([]);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const navigate = useNavigate();
  const id = location.pathname === '/profile' ? loggedInUser.id : useParams().id;
  const doesEditMyslef = id === loggedInUser._id;

  useEffect(() => {
    API.get('/api/hospitals').then(response => {
      setHospitals(response.data);
    }).catch(error => console.error('Fetching hospitals failed:', error));
  
    if (id) {
      API.get(`/api/users/${id}`).then(response => {
        const userRolesWithHospitals = response.data.roles.map(role => ({
          ...role,
          hospital: role.hospital._id
        }));
        setUser({ 
          ...response.data, 
          roles: userRolesWithHospitals, 
          password: '',        
        });
      }).catch(error => console.error('Fetching user failed:', error));
    }
  }, [id]);

  const handleRoleChange = (index, field, value) => {
    const newRoles = user.roles.map((role, idx) => {
      if (idx === index) {
        return { ...role, [field]: value };
      }
      return role;
    });
    setUser({ ...user, roles: newRoles });
  };

  const addRole = () => {
    setUser({ ...user, roles: [...user.roles, { hospital: '', role: 'USER' }] });
  };

  const removeRole = index => {
    setUser({ ...user, roles: user.roles.filter((_, idx) => idx !== index) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let userToSubmit = { ...user };
      if (doesEditMyslef) {
        // Remove the 'roles' property if the user is editing themselves
        const { roles, ...userWithoutRoles } = userToSubmit;
        userToSubmit = userWithoutRoles;
      }
      await API.put(`/api/users/${id}`, userToSubmit);
      if (doesEditMyslef) {
        navigate('/protocols');
      } else {
        navigate('/users');
      }
    } catch (error) {
      console.error('Updating user failed:', error);
    }
  };

  const handleSuperAdminChange = (value) => {
    setUser({ ...user, isSuperAdmin: value});
  }

  const canChangePassword = loggedInUser.isSuperAdmin || 
    loggedInUser._id === id || 
    user.roles.some(role => 
      loggedInUser.roles.some(loggedInRole => 
        loggedInRole.hospital === role.hospital && loggedInRole.role === 'ADMIN'
      )
    );

  return (
    <CommonLayout>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <div className="edit-user-container">
            <div className="user-header">
            <Typography variant="h5" style={{fontWeight:"bold", padding: "5px", marginBottom: "20px", textAlign: "center"}}>
                עריכת משתמש
              </Typography>
            </div>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="שם"
                    value={user.name}
                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <TextField
                      fullWidth
                      label="אימייל"
                      value={user.email}
                      onChange={(e) => setUser({ ...user, email: e.target.value })}
                      required
                      disabled={!loggedInUser.isSuperAdmin}
                    />
                    {canChangePassword && (
                      <Button
                        variant="contained"
                        onClick={() => setOpenPasswordDialog(true)}
                        style={{ 
                          marginRight: '20px', 
                          minWidth: '120px',
                          backgroundColor: '#1FB5A3',
                        }}
                      >
                        שנה סיסמה
                      </Button>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="טלפון"
                    value={user.phone}
                    onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="hospital-role-label">תפקיד בבית חולים</InputLabel>
                    <Select
                      labelId="hospital-role-label"
                      value={user.hospitalRole}
                      onChange={(e) => setUser({ ...user, hospitalRole: e.target.value })}
                      label="תפקיד בבית חולים"
                    >
                      {hospitalRoles.map((role) => (
                        <MenuItem key={role.value} value={role.value}>
                          {role.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {!doesEditMyslef && <PermissionsManager 
                  roles={user.roles}
                  hospitals={hospitals}
                  loggedInSuperAdmin={loggedInUser.isSuperAdmin}
                  isSuperAdmin={user.isSuperAdmin}
                  onChangeSuperAdmin={handleSuperAdminChange}
                  onRoleChange={handleRoleChange}                  
                  onAddRole={addRole}
                  onRemoveRole={removeRole}
                />}
                <Grid item xs={12} className="submit-button-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <Button type="submit" variant="contained" color="primary" className="submit-button" style={{ minWidth: '200px' }}>
                    עדכן
                  </Button>
                </Grid>
              </Grid>
            </form>

            <ChangePasswordDialog 
              open={openPasswordDialog}
              onClose={() => setOpenPasswordDialog(false)}
              userId={id}
            />
          </div>
        </ThemeProvider>
      </CacheProvider>
    </CommonLayout>
  );
}

export default EditUser;