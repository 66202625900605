import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import ErrorDialog from './components/ErrorDialog';
import AppRoutes from './Routes';
import './App.css';

function App() {
  const [authToken, setAuthToken] = useState(null);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorObject, setErrorObject] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }

    window.showErrorDialog = (error) => {
      setErrorObject(error);
      setErrorDialogOpen(true);
    };

    return () => {
      // Clean up the global error handler
      delete window.showErrorDialog;
    };
  }, []);

  const setToken = (token) => {
    localStorage.setItem('token', token);
    setAuthToken(token);
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
    setErrorObject(null);
  };
  
  const isLoginPage = location.pathname === '/login';
  const isProtocolViewer = location.pathname.includes('/protocols/') && location.pathname.includes('/view');

  return (
    <div className="App">
      <Header />
      <div className="content-wrapper">
        <main className={isProtocolViewer || isLoginPage ? 'full-width' : ''}>
          <AppRoutes authToken={authToken} setToken={setToken} />
        </main>
        {!isLoginPage && !isProtocolViewer && <Sidebar />}
      </div>
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        error={errorObject}
      />
    </div>
  );
}

export default App;