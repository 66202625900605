import React, { useEffect } from 'react'; // Make sure useEffect is imported
import { Route, Routes, Navigate } from 'react-router-dom';
import Protocols from './pages/Protocols';
import AddEditProtocol from './pages/AddEditProtocol';
import Hospitals from './pages/Hospitals';
import Login from './pages/Login';
import Users from './pages/Users';
import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser';
import ProtocolViewer from './pages/ProtocolViewer';

const AppRoutes = ({ authToken, setToken }) => {
  if (!authToken) {
    authToken = localStorage.getItem('token');
  }
  return (
    <Routes>
      <Route path="/login" element={<Login setToken={setToken} />} />
      <Route path="/protocols" element={authToken ? <Protocols /> : <Navigate replace to="/login" />} />
      <Route path="/protocols/new" element={authToken ? <AddEditProtocol /> : <Navigate replace to="/login" />} />
      <Route path="/protocols/:id/view" element={authToken ? <ProtocolViewer /> : <Navigate replace to="/login" />} />
      <Route path="/protocols/:id/edit" element={authToken ? <AddEditProtocol /> : <Navigate replace to="/login" />} />      

      <Route path="/hospitals" element={authToken ? <Hospitals /> : <Navigate replace to="/login" />} />

      <Route path="/profile" element={authToken ? <EditUser /> : <Navigate replace to="/login" />} />
      <Route path="/users" element={authToken ? <Users /> : <Navigate replace to="/login" />} />
      <Route path="/users/new" element={authToken ? <AddUser /> : <Navigate replace to="/login" />} />
      <Route path="/users/:id" element={authToken ? <EditUser /> : <Navigate replace to="/login" />} />

      <Route path="/" element={<Navigate replace to={authToken ? "/protocols" : "/login"} />} />
    </Routes>
  );
};

export default AppRoutes;
