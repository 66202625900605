import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { UserContext } from '../context/UserContext';
import CommonLayout from '../components/CommonLayout';
import API from '../config/axiosConfig';
import './Users.css';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// Create RTL theme
const theme = createTheme({
  direction: 'rtl',
});

const Users = () => {
  const [users, setUsers] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null);
  const [selectedHospital, setSelectedHospital] = useState('');
  const { user, hospitals } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, [selectedHospital]);

  const fetchUsers = async () => {
    try {
      const res = await API.get('/api/users', {
        params: { hospital: selectedHospital }
      });
      setUsers(res.data);
    } catch (error) {
      console.error('Fetching users failed:', error);
    }
  };

  const handleRowClick = (id) => {
    navigate(`/users/${id}`);
  };

  const openDeleteConfirm = (e, user) => {
    e.stopPropagation(); // Prevent row click when clicking delete
    setDeleteUser(user);
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    try {
      await API.delete(`/api/users/${deleteUser._id}`);
      fetchUsers();
      setDeleteConfirmOpen(false);
    } catch (error) {
      console.error('Deletion failed:', error);
    }
  };

  const canManageUsers = user && (user.isSuperAdmin || user.roles.filter(role => role.role === 'ADMIN').length > 1);

  const userHospitals = user.isSuperAdmin 
    ? hospitals 
    : hospitals.filter(hospital => 
        user.roles.some(role => role.hospital === hospital._id && role.role === 'ADMIN')
      );

  return (
    <CommonLayout>
      <div className="users-container">
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            {canManageUsers && (
              <FormControl fullWidth className="hospital-select">
                <InputLabel id="hospital-select-label">בית חולים</InputLabel>
                <Select
                  labelId="hospital-select-label"
                  value={selectedHospital}
                  label="בית חולים"
                  onChange={(e) => setSelectedHospital(e.target.value)}
                >
                  <MenuItem value="">כל בתי החולים</MenuItem>
                  {userHospitals.map((hospital) => (
                    <MenuItem key={hospital._id} value={hospital._id}>{hospital.nameHebrew}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <div className="users-list">
              {users.map((user) => (
                <div
                  key={user._id}
                  className="user-item"
                  onClick={() => handleRowClick(user._id)}
                >
                  <span className="user-name">{user.name}</span>
                  <div className="user-actions">
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={(e) => openDeleteConfirm(e, user)}
                      className="action-icon"
                    />
                  </div>
                </div>
              ))}
            </div>

            <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
              <DialogTitle>האם אתה בטוח?</DialogTitle>
              <DialogContent>
                <p>האם אתה בטוח שברצונך למחוק את המשתמש {deleteUser?.name}?</p>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteConfirmOpen(false)}>ביטול</Button>
                <Button onClick={handleDelete} color="error">
                  מחק
                </Button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
        </CacheProvider>

        <button className="floating-add-btn" onClick={() => navigate('/users/new')}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </CommonLayout>
  );
};

export default Users;