import React from 'react';
import { createRoot } from 'react-dom/client';  // Import createRoot from react-dom/client
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './index.css';
import { UserProvider } from './context/UserContext';  // Import the UserProvider

const container = document.getElementById('root'); // Get the root element
const root = createRoot(container); // Create a root

root.render(  // Use the root to render your component tree
  <React.StrictMode>
    <Router>
      <UserProvider>  {/* Wrap App in UserProvider */}
        <App />
      </UserProvider>
    </Router>
  </React.StrictMode>
);
