import React, { useEffect, useState } from 'react';

const STORAGE_KEY = 'recentlyViewedProtocols';
const MAX_RECENT_PROTOCOLS = 10;

const RecentProtocolsManager = () => {

  const updateLocalStorage = (updatedProtocols) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedProtocols));
  };

  const protocolAccessed = (protocolId) => {    
    const currentTime = new Date().toISOString();
    var recentProtocols = getLastRecentlyUsedProtocols();
    const updatedProtocols = recentProtocols?.filter(p => p.id !== protocolId) || [];
    updatedProtocols.unshift({ id: protocolId, lastAccessed: currentTime });
    
    if (updatedProtocols.length > MAX_RECENT_PROTOCOLS) {
      updatedProtocols.pop();
    }
    updateLocalStorage(updatedProtocols);
  };

  const getLastRecentlyUsedProtocols = () => {
    const storedProtocols = localStorage.getItem(STORAGE_KEY);
    if (storedProtocols) {
      return JSON.parse(storedProtocols);
    }
    return [];
  };

  return {
    protocolAccessed,
    getLastRecentlyUsedProtocols,
  };
};

export default RecentProtocolsManager;