// frontend/src/services/protocolFileService.js
import API from '../config/axiosConfig';
import { useState, useEffect } from 'react';

class ProtocolFileService {
  constructor() {
    this.tokenCache = new Map(); // Cache tokens with their expiration
  }

  async getSignedUrl(fileUrl) {
    try {
      // Extract the blob path from the full URL
      const blobPath = new URL(fileUrl).pathname.split('/').slice(2).join('/');
      
      // Check cache first
      const cachedToken = this.tokenCache.get(blobPath);
      if (cachedToken && cachedToken.expiry > Date.now()) {
        return `${fileUrl}?${cachedToken.token}`;
      }

      // Get new token from backend
      const response = await API.get(`/api/protocols/file-access/${encodeURIComponent(blobPath)}`);
      const { sasToken, expiresIn } = response.data;
      
      // Cache the token
      this.tokenCache.set(blobPath, {
        token: sasToken,
        expiry: Date.now() + (expiresIn * 1000) // expiresIn should be in seconds
      });

      return `${fileUrl}?${sasToken}`;
    } catch (error) {
      console.error('Error getting signed URL:', error);
      throw error;
    }
  }

  clearCache() {
    this.tokenCache.clear();
  }
}

export const protocolFileService = new ProtocolFileService();

// Usage in components:
export const useProtocolFile = (fileUrl) => {
  const [signedUrl, setSignedUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getSignedUrl = async () => {
      if (!fileUrl) return;
      
      try {
        setLoading(true);
        const url = await protocolFileService.getSignedUrl(fileUrl);
        setSignedUrl(url);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getSignedUrl();
  }, [fileUrl]);

  return { signedUrl, loading, error };
};