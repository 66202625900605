import React from 'react';
import './CommonLayout.css';

const CommonLayout = ({ children }) => {
  return (
    <div className="common-layout">
      {children}
    </div>
  );
};

export default CommonLayout;