import React from 'react';
import { 
  Grid, Typography, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const PermissionsManager = ({ 
  roles, 
  hospitals, 
  loggedInSuperAdmin,
  isSuperAdmin, 
  onChangeSuperAdmin,
  onRoleChange, 
  onAddRole, 
  onRemoveRole 
}) => {
  const singleHospital = hospitals.length === 1 ? hospitals[0] : null;
  return (
    <Grid item xs={12}>
        {loggedInSuperAdmin && (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox                
                checked={isSuperAdmin}
                onChange={(e) => onChangeSuperAdmin(e.target.checked)}
                name="isSuperAdmin"
              />
            }
            label="מנהל על"
          />
        </Grid>
      )}
      <Typography variant="h6">הרשאות:</Typography>
      {roles?.map((role, index) => (
        <Grid container spacing={2} key={index} alignItems="center" style={{ marginBottom: '10px' }}>
          <Grid item xs={5}>
            <FormControl fullWidth disabled={isSuperAdmin || singleHospital}>
              <InputLabel>בית חולים</InputLabel>
              <Select
                value={singleHospital ? singleHospital._id : role.hospital}
                onChange={(e) => onRoleChange(index, 'hospital', e.target.value)}
                required
              >
                {singleHospital ? (
                  <MenuItem value={singleHospital._id}>{singleHospital.nameHebrew}</MenuItem>
                ) : (
                  [
                    <MenuItem key="default" value="">
                      <em>בחר בית חולים</em>
                    </MenuItem>,
                    ...hospitals.map(hospital => (
                      <MenuItem key={hospital._id} value={hospital._id}>{hospital.nameHebrew}</MenuItem>
                    ))
                  ]
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth disabled={isSuperAdmin}>
              <InputLabel>תפקיד</InputLabel>
              <Select
                value={role.role}
                onChange={(e) => onRoleChange(index, 'role', e.target.value)}
                required
              >
                <MenuItem value="USER">משתמש</MenuItem>
                <MenuItem value="EDITOR">עורך</MenuItem>
                <MenuItem value="ADMIN">מנהל</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={() => onRemoveRole(index)}
              variant="contained"
              style={{ 
                backgroundColor: isSuperAdmin ? '#B9EDE7' : '#1FB5A3', 
                color: isSuperAdmin ? 'rgba(0, 0, 0, 0.26)' : 'white' 
              }}
              disabled={isSuperAdmin}
            >
              <FontAwesomeIcon icon={faTrash} /> הסר
            </Button>
          </Grid>
        </Grid>
      ))}
      <Button
        onClick={onAddRole}
        variant="contained"
        style={{ 
          backgroundColor: isSuperAdmin ? '#B9EDE7' : '#1FB5A3', 
          color: isSuperAdmin ? 'rgba(0, 0, 0, 0.26)' : 'white',
          marginTop: '10px' 
        }}
        disabled={isSuperAdmin}
      >
        <FontAwesomeIcon icon={faPlus} /> הוסף הרשאה
      </Button>
    </Grid>
  );
};

export default PermissionsManager;