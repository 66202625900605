import React, { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import API from '../config/axiosConfig';  // Make sure this path is correct

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hospitals, setHospitals] = useState([]);
    const [departments, setDepartments] = useState([]);

    const fetchUserData = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedUser = jwtDecode(token);
                console.log("Decoded user from token: ", decodedUser);
                
                // Fetch additional user data from the server
                const response = await API.get('/api/users/me');
                const userData = response.data;
                console.log("User data from server: ", userData);
                
                // Combine decoded token data with server data
                setUser({ ...decodedUser, ...userData });
            } catch (error) {
                console.log("Failed to fetch user data:", error);
                localStorage.removeItem('token');
                setUser(null);
            }
        }
        setLoading(false);
    }, []);

    const fetchHospitals = useCallback(async () => {
        try {
            const response = await API.get('/api/hospitals');
            setHospitals(response.data);
        } catch (error) {
            console.error('Fetching hospitals failed:', error);
        }
    }, []);

    const fetchDepartments = useCallback(async (hospitalId) => {
        try {
            const response = await API.get(`/api/hospitals/${hospitalId}/departments`);
            setDepartments(response.data);
        } catch (error) {
            console.error('Fetching departments failed:', error);
        }
    }, []);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    useEffect(() => {
        if (user) {
            fetchHospitals();
        }
    }, [user, fetchHospitals]);

    const login = async (token) => {
        localStorage.setItem('token', token);
        await fetchUserData();
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
        setHospitals([]);
        setDepartments([]);
    };

    const refreshUserData = () => {
        fetchUserData();
    };

    const createDepartment = async (hospitalId, newDepartment) => {
        try {
            const response = await API.post(`/api/hospitals/${hospitalId}/departments`, newDepartment);
            setDepartments(prevDepartments => [...prevDepartments, response.data]);
            fetchHospitals();
            return response.data;
        } catch (error) {
            console.error('Creating department failed:', error);
            throw error;
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Or any loading indicator
    }

    return (
        <UserContext.Provider value={{ 
            user, 
            login, 
            logout, 
            refreshUserData, 
            hospitals, 
            departments,
            createDepartment,
            fetchHospitals
        }}>
            {children}
        </UserContext.Provider>
    );
};