import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaBars, FaFileContract, FaSignOutAlt, FaHospital, FaUsers, FaTimes } from 'react-icons/fa';
import { UserContext } from '../context/UserContext';
import './Sidebar.css';

const Sidebar = () => {
 const [isExpanded, setIsExpanded] = useState(window.innerWidth >= 1024);
 const { user, hospitals, logout } = useContext(UserContext);
 const navigate = useNavigate();
 const location = useLocation();
 const [activeHospitals, setActiveHospitals] = useState({});
 const [activeMenuItem, setActiveMenuItem] = useState('');
 const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

 useEffect(() => {
   const handleResize = () => {
     const mobile = window.innerWidth < 1024;
     setIsMobile(mobile);
     setIsExpanded(!mobile);
   };

   window.addEventListener('resize', handleResize);
   return () => window.removeEventListener('resize', handleResize);
 }, []);

 useEffect(() => {
   setActiveMenuItem(location.pathname + location.search);
   
   // Collapse menu on mobile when route changes
   if (isMobile) {
     setIsExpanded(false);
   }
 }, [location, isMobile]);

 const handleLogout = () => {
   logout();
   if (isMobile) {
     setIsExpanded(false);
   }
   navigate('/login');
 };

 const handleMenuItemClick = () => {
   if (isMobile) {
     setIsExpanded(false);
   }
 };

 if (!user) {
   return null;
 }

 const menuItemClass = (path) => 
   `menu-item ${activeMenuItem === path ? 'active' : ''}`;

 return (
   <div className="sidebar-container">
     <button
       className={`toggle-btn ${isExpanded ? 'expanded' : 'collapsed'}`}
       onClick={() => setIsExpanded(true)}
     >
       <FaBars />
     </button>

     <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
       {isExpanded && (
         <nav>
           <ul>
             <li className={menuItemClass('/profile')}>
               <Link to="/profile" className="full-width-link" onClick={handleMenuItemClick}>
                 <FaUsers style={{ marginRight: '10px' }} />פרופיל
               </Link>
             </li>
             <li className="divider"></li>
             
             {(user.isSuperAdmin || user.roles.some(role => role.role === 'ADMIN')) && (
               <>
                 <li className={menuItemClass('/users')}>
                   <Link to="/users" className="full-width-link" onClick={handleMenuItemClick}>
                     <FaUsers style={{ marginRight: '10px' }} />משתמשים
                   </Link>
                 </li>
                 <li className="divider"></li>
               </>
             )}

             {user.isSuperAdmin && (
               <>
                 <li className={menuItemClass('/hospitals')}>
                   <Link to="/hospitals" className="full-width-link" onClick={handleMenuItemClick}>
                     <FaHospital style={{ marginRight: '10px' }} />בתי חולים
                   </Link>
                 </li>
                 <li className="divider"></li>
               </>
             )}

             <li className={menuItemClass('/protocols')}>
               <Link to="/protocols" className="full-width-link" onClick={handleMenuItemClick}>
                 <FaFileContract style={{ marginRight: '10px' }} />פרוטוקולים
               </Link>
             </li>
             <li className="divider" style={{height: '3px'}}></li>

             <li className={menuItemClass('logout')}>
               <button className="logout-button full-width-link" onClick={handleLogout}>
                 <FaSignOutAlt style={{ marginRight: '10px' }} />התנתק
               </button>
             </li>
           </ul>
         </nav>
       )}
     </div>

     <button
       className={`close-btn ${isExpanded ? 'visible' : ''}`}
       onClick={() => setIsExpanded(false)}
     >
       <FaTimes />
     </button>
   </div>
 );
};

export default Sidebar;