import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { UserContext } from '../context/UserContext';
import API from '../config/axiosConfig';
import './Hospitals.css';
import CommonLayout from '../components/CommonLayout';
import { primaryButtonStyle, secondaryButtonStyle, dialogActionsStyle } from '../utils/sharedStyles';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// Create RTL theme
const theme = createTheme({
  direction: 'rtl',
});

const Hospitals = () => {
  const [expandedHospital, setExpandedHospital] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [editItem, setEditItem] = useState(null);
  const [nameHebrew, setNameHebrew] = useState('');
  const [nameEnglish, setNameEnglish] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [hoveredHospital, setHoveredHospital] = useState(null);
  const [nameHebrewError, setNameHebrewError] = useState('');
  const [nameEnglishError, setNameEnglishError] = useState('');
  const [attemptedSave, setAttemptedSave] = useState(false);
  const { user, hospitals, fetchHospitals, createDepartment } = useContext(UserContext);

  const handleExpandHospital = (id) => {
    setExpandedHospital(expandedHospital === id ? null : id);
  };

  const openDialog = (type, item = null) => {
    setDialogType(type);
    setEditItem(item);
    setNameHebrew(item ? item.nameHebrew || '' : '');
    setNameEnglish(item ? item.nameEnglish || '' : '');
    setDialogOpen(true);
    setAttemptedSave(false);
    setNameHebrewError('');
    setNameEnglishError('');
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setNameHebrew('');
    setNameEnglish('');
    setEditItem(null);
    setAttemptedSave(false);
    setNameHebrewError('');
    setNameEnglishError('');
  };

  const validateInput = () => {
    let isValid = true;
    setAttemptedSave(true);

    if (!nameHebrew) {
      setNameHebrewError('שדה חובה');
      isValid = false;
    } else {
      setNameHebrewError('');
    }

    if (dialogType === 'createDepartment' || dialogType === 'editDepartment') {
      const hospital = hospitals.find(h => h._id === editItem.hospital);
      const isDuplicateHebrew = hospital.departments.some(dep => 
        dep.nameHebrew === nameHebrew && (!editItem._id || dep._id !== editItem._id)
      );
      const isDuplicateEnglish = hospital.departments.some(dep => 
        dep.nameEnglish === nameEnglish && nameEnglish !== '' && (!editItem._id || dep._id !== editItem._id)
      );

      if (isDuplicateHebrew) {
        setNameHebrewError('קיימת כבר מחלקה עם שם דומה');
        isValid = false;
      }

      if (isDuplicateEnglish) {
        setNameEnglishError('קיימת כבר מחלקה עם שם דומה באנגלית');
        isValid = false;
      }
    }

    return isValid;
  };

  const handleSave = async () => {
    if (!validateInput()) return;

    try {
      if (dialogType === 'editHospital' || dialogType === 'createHospital') {
        const method = dialogType === 'editHospital' ? 'put' : 'post';
        const url = dialogType === 'editHospital' ? `/api/hospitals/${editItem._id}` : '/api/hospitals';
        await API[method](url, { nameHebrew, nameEnglish });
      } else if (dialogType === 'createDepartment') {
        await createDepartment(editItem.hospital, { nameHebrew, nameEnglish });
      } else {
        await API.put(`/api/hospitals/${editItem.hospital}/departments/${editItem._id}`, { nameHebrew, nameEnglish });
      }
      fetchHospitals();
      closeDialog();
    } catch (error) {
      console.error('Saving failed:', error);
    }
  };

  const openDeleteConfirm = (item, type) => {
    setDeleteItem({ ...item, type });
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    try {
      if (deleteItem.type === 'hospital') {
        await API.delete(`/api/hospitals/${deleteItem._id}`);
      } else {
        await API.delete(`/api/hospitals/${deleteItem.hospital}/departments/${deleteItem._id}`);
      }
      fetchHospitals();
      setDeleteConfirmOpen(false);
    } catch (error) {
      console.error('Deletion failed:', error);
    }
  };

  const canEditHospital = user && user.isSuperAdmin;
  const canEditDepartment = (hospitalId) =>
    user &&
    (user.isSuperAdmin ||
      user.roles.some(
        (role) =>
          role.hospital === hospitalId && (role.role === 'EDITOR' || role.role === 'ADMIN')
      ));

  return (
    <CommonLayout>
    <div className="hospitals-container">
      {hospitals.map((hospital) => (
        <div 
          key={hospital._id} 
          className="hospital-item"
          onMouseEnter={() => setHoveredHospital(hospital._id)}
          onMouseLeave={() => setHoveredHospital(null)}
        >
          <div className="hospital-header" onClick={() => handleExpandHospital(hospital._id)}>
            <div className="hospital-title">
              <FontAwesomeIcon
                icon={expandedHospital === hospital._id ? faChevronUp : faChevronDown}
                className="expand-icon"
              />
              <h2 style={{color:"white"}}>{hospital.nameHebrew}</h2>
            </div>
            <div className="hospital-actions">
              {canEditHospital && hoveredHospital === hospital._id && (
                <>
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={(e) => {
                      e.stopPropagation();
                      openDialog('editHospital', hospital);
                    }}
                    className="action-icon"
                    style={{color: "white"}}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={(e) => {
                      e.stopPropagation();
                      openDeleteConfirm(hospital, 'hospital');
                    }}
                    className="action-icon"
                    style={{color: "white"}}
                  />
                </>
              )}
            </div>
          </div>
          {expandedHospital === hospital._id && (
            <div className="departments-list">
              {hospital.departments.map((department) => (
                <div key={department._id} className="department-item">
                  <span>{department.nameHebrew}</span>
                  {canEditDepartment(hospital._id) && (
                    <div className="department-actions">
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => openDialog('editDepartment', { ...department, hospital: hospital._id })}
                        className="action-icon"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => openDeleteConfirm({ ...department, hospital: hospital._id }, 'department')}
                        className="action-icon"
                      />
                    </div>
                  )}
                </div>
              ))}
              {canEditDepartment(hospital._id) && (
                <div className="add-department-container">
                  <button
                    className="add-department-btn"
                    onClick={() => openDialog('createDepartment', { hospital: hospital._id })}
                  >
                    הוסף מחלקה
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      ))}

      {canEditHospital && (
        <button className="floating-add-btn" onClick={() => openDialog('createHospital')}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      )}

      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <Dialog open={dialogOpen} onClose={closeDialog}>
            <DialogTitle>
              {dialogType.includes('Hospital') ? 'בית חולים' : 'מחלקה'}
            </DialogTitle>
            <DialogContent>              
              <TextField
                autoFocus
                margin="dense"
                label={dialogType.includes('Hospital') ? 'שם בית החולים' : 'שם המחלקה'}
                fullWidth
                value={nameHebrew || ''}
                onChange={(e) => setNameHebrew(e.target.value)}
                error={!!nameHebrewError}
                helperText={nameHebrewError}
              />
              <TextField
                margin="dense"
                label={dialogType.includes('Hospital') ? 'שם בית החולים באנגלית' : 'שם המחלקה באנגלית'}
                fullWidth
                value={nameEnglish || ''}
                onChange={(e) => setNameEnglish(e.target.value)}
                error={!!nameEnglishError}
                helperText={nameEnglishError}
              />
            </DialogContent>
            <DialogActions style={dialogActionsStyle}>
              <Button onClick={closeDialog} style={secondaryButtonStyle}>
                ביטול
              </Button>
              <Button 
                onClick={handleSave} 
                style={primaryButtonStyle}
              >
                שמור
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
            <DialogTitle>האם אתה בטוח?</DialogTitle>
            <DialogContent>
              {deleteItem?.type === 'hospital' ? (
                <p>במחיקה של בית חולים תאבד את כל ההגדרות שלו ואת כל הפרוטוקולים שלו.</p>
              ) : (
                <p>
                  במחיקה של מחלקה תאבד את כל הפרוטוקולים המשוייכים אליה. לפני מחיקה מומלץ לוודא קודם מה הפרוטוקולים המשוייכים למחלקה זו {' '}
                  <Link to={`/protocols?dep=${deleteItem?._id}`}>כאן</Link>.
                </p>
              )}
            </DialogContent>
            <DialogActions style={dialogActionsStyle}>
              <Button onClick={() => setDeleteConfirmOpen(false)} style={secondaryButtonStyle}>
                ביטול
              </Button>
              <Button onClick={handleDelete} style={primaryButtonStyle}>
                מחק
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </CacheProvider>
    </div>
    </CommonLayout>
  );
};

export default Hospitals;