import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Chip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { UserContext } from '../context/UserContext';
import API from '../config/axiosConfig';
import CommonLayout from '../components/CommonLayout';
import ProtocolSearchBox from '../components/ProtocolSearchBox';
import RecentProtocolsManager from '../components/RecentProtocolsManager';
import { protocolFileService } from '../services/protocolFileService';
import './Protocols.css';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#1FB5A3',
          color: 'white',
          margin: '0 4px 4px 0',
        },
      },
    },
  },
});

const isMobileDevice = () => {
  return (
    typeof window !== 'undefined' &&
    (window.innerWidth <= 768 || 
     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  );
};

function Protocols() {
  const [protocols, setProtocols] = useState([]);
  const [filteredProtocols, setFilteredProtocols] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteProtocol, setDeleteProtocol] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { getLastRecentlyUsedProtocols } = RecentProtocolsManager();

  useEffect(() => {
    const fetchProtocols = async () => {
      try {
        const depParam = new URLSearchParams(location.search).get('dep');
        const url = depParam ? `/api/protocols?departmentId=${depParam}` : '/api/protocols';
        const res = await API.get(url);
        setProtocols(res.data);
        sortAndFilterProtocols(res.data);
      } catch (error) {
        console.error('Error fetching protocols:', error);
      }
    };

    fetchProtocols();
  }, [location]);

  const sortAndFilterProtocols = (protocolsToSort, filters = {}) => {
    const recentlyUsedProtocols = getLastRecentlyUsedProtocols();
    
    const sortedProtocols = protocolsToSort.sort((a, b) => {
      const aIndex = recentlyUsedProtocols.findIndex(p => p.id === a._id);
      const bIndex = recentlyUsedProtocols.findIndex(p => p.id === b._id);
      
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });

    const filtered = sortedProtocols.filter(protocol => {
      const matchesHospital = !filters.hospital || protocol.hospital._id === filters.hospital || protocol.hospital === filters.hospital;
      const matchesDepartment = !filters.department || protocol.departments.includes(filters.department);
      const matchesTags = !filters.tags || filters.tags.length === 0 || filters.tags.every(tag => protocol.tags.includes(tag));
      const matchesSearchTerm = !filters.searchTerm || 
        protocol.name.toLowerCase().includes(filters.searchTerm.toLowerCase());

      return matchesHospital && matchesDepartment && matchesTags && matchesSearchTerm;
    });

    setFilteredProtocols(filtered);
  };

  const handleSearch = (filters) => {
    sortAndFilterProtocols(protocols, filters);
  };

  const handleView = async (id) => {
    const protocol = protocols.find(p => p._id === id);
    if (isMobileDevice() && protocol?.currentRevision?.type === 'AZURE_BLOB') {
      try {
        // Get signed URL for the PDF
        const baseUrl = protocol.currentRevision.url || protocol.fileUrl;
        const signedUrl = await protocolFileService.getSignedUrl(baseUrl);
        window.open(signedUrl, '_blank');
      } catch (error) {
        console.error('Error getting signed URL:', error);
        // Fallback to viewer if there's an error
        navigate(`/protocols/${id}/view`);
      }
    } else {
      navigate(`/protocols/${id}/view`);
    }
  };

  const handleEdit = (e, id) => {
    e.stopPropagation();
    navigate(`/protocols/${id}/edit`);
  };

  const openDeleteConfirm = (e, protocol) => {
    e.stopPropagation();
    setDeleteProtocol(protocol);
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    try {
      await API.delete(`/api/protocols/${deleteProtocol._id}`);
      setProtocols(protocols.filter(p => p._id !== deleteProtocol._id));
      setFilteredProtocols(filteredProtocols.filter(p => p._id !== deleteProtocol._id));
      setDeleteConfirmOpen(false);
    } catch (error) {
      console.error('Deletion failed:', error);
    }
  };

  const handleAddNewProtocol = () => {
    const searchParams = new URLSearchParams(location.search);
    const depParam = searchParams.get('dep');
    
    if (depParam) {
      navigate(`/protocols/new?dep=${depParam}`);
    } else {
      navigate('/protocols/new');
    }
  };
  
  const canCreateProtocol = () => {
    return user && (user.isSuperAdmin || user.roles.some(
      role => ['EDITOR', 'ADMIN'].includes(role.role)
    ));
  };

  const canEditProtocol = (protocol) => {
    return user && (user.isSuperAdmin || user.roles.some(
      role => role.hospital === protocol.hospital && ['EDITOR', 'ADMIN'].includes(role.role)
    ));
  };

  return (
    <CommonLayout>
      <div className="protocols-container">
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <ProtocolSearchBox onSearch={handleSearch} />

            <div className="protocols-list">
              {filteredProtocols.map(protocol => (
                <div
                key={protocol._id}
                className="protocol-item"
                onClick={() => handleView(protocol._id)}
              >
                <div className="protocol-info">
                  <span className="protocol-name">{protocol.name}</span>
                </div>
                {canEditProtocol(protocol) && (
                  <div className="protocol-actions">
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={(e) => handleEdit(e, protocol._id)}
                      className="action-icon"
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={(e) => openDeleteConfirm(e, protocol)}
                      className="action-icon"
                    />
                  </div>
                )}
              </div>
              ))}
            </div>

            <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
              <DialogTitle>האם אתה בטוח שברצונך למחוק את הפרוטוקול {deleteProtocol?.name}?</DialogTitle>
              <DialogActions>
                <Button onClick={() => setDeleteConfirmOpen(false)}>ביטול</Button>
                <Button onClick={handleDelete} color="error">
                  מחק
                </Button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
        </CacheProvider>

        {canCreateProtocol() && <button className="floating-add-btn" onClick={handleAddNewProtocol}>
          <FontAwesomeIcon icon={faPlus} />
        </button>}
      </div>
    </CommonLayout>
  );
}

export default Protocols;