import axios from 'axios';
import './env.js';

const API = axios.create({
  baseURL: window._env_.REACT_APP_API_BASE_URL
});


API.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Exclude login errors
    if (error.config.url.includes('/login')) {
      return Promise.reject(error);
    }

    // Create an error object to be used by the ErrorDialog
    const errorObject = {
      code: error.response?.status,
      message: error.response?.data?.error?.message,
      hebMessage: error.response?.data?.error?.hebMessage,
    };

    // Dispatch an action to show the error dialog
    if (window.showErrorDialog) {
      window.showErrorDialog(errorObject);
    }

    return Promise.reject(error);
  }
);

export default API;