import React, { useState } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  TextField, Button, Typography 
} from '@mui/material';
import API from '../config/axiosConfig';
import { primaryButtonStyle, secondaryButtonStyle, dialogActionsStyle } from '../utils/sharedStyles';


const ChangePasswordDialog = ({ open, onClose, userId }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handlePasswordChange = async () => {
    if (newPassword && newPassword.length < 6) {
      setPasswordError('סיסמה צריכה להכיל מינימום 6 תוים');
      return;
    }
    else if (newPassword !== confirmPassword) {
      setPasswordError('הסיסמאות אינן תואמות');
      return;
    }
    try {
      await API.post(`/api/users/${userId}/set-password`, { newPassword });
      setNewPassword('');
      setConfirmPassword('');
      setPasswordError('');
      onClose();
    } catch (error) {
      console.error('Password change failed:', error);
      setPasswordError('שינוי הסיסמה נכשל');
    }
  };

  const onCloseClicked = async () => {
    setNewPassword('');
    setConfirmPassword('');
    setPasswordError('');
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '8px',
          padding: '16px',
        },
      }}
    >
      <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', color: '#103C6E' }}>
        שינוי סיסמה
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="הזן סיסמה"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          variant="outlined"
        />
        <TextField
          margin="dense"
          label="חזור שנית על הסיסמה"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          variant="outlined"
          InputLabelProps={{
            style: { color: '#666' },
          }}
        />
        {passwordError && <Typography color="error">{passwordError}</Typography>}
      </DialogContent>
      <DialogActions style={dialogActionsStyle}>
        <Button 
          onClick={onCloseClicked}
          style={secondaryButtonStyle}
        >
          ביטול
        </Button>
        <Button 
          onClick={handlePasswordChange} 
          style={primaryButtonStyle}
        >
          שמור
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;