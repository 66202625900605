// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-layout {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  @media (max-width: 800px) {
    .common-layout {
      width: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/CommonLayout.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE;MACE,WAAW;IACb;EACF","sourcesContent":[".common-layout {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    box-sizing: border-box;\n  }\n  \n  @media (max-width: 800px) {\n    .common-layout {\n      width: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
