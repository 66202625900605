import React, { useContext } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#1FB5A3',
          color: 'white',
          margin: '0 0 4px 4px',
        },
        deleteIcon: {
          color: 'white',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#103C6E',
          color: 'white',
          '&:hover': {
            backgroundColor: '#0d3157',
          },
        },
      },
    },
  },
});

const ProtocolDetailsDialog = ({ open, onClose, protocol, department }) => {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('he-IL', { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  };
  const allRevisions = [...protocol.revisions].reverse();

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Dialog 
          open={open} 
          onClose={onClose}
          PaperProps={{
            style: {
              borderRadius: '8px',
              padding: '16px',
              maxWidth: '600px',
              width: '100%',
            },
          }}
        >
          <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', color: '#103C6E' }}>
            פרטי פרוטוקול
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom>שם: {protocol.name}</Typography>
            <Typography variant="h6" gutterBottom>בית חולים: {protocol.hospital.nameHebrew}</Typography>            
            <Typography variant="h6" gutterBottom>מחלקה: {department}</Typography>            
            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }}>
              {protocol.tags.map((tag, index) => (
                <Chip key={index} label={tag} style={{ margin: '0 4px 4px 0' }} />
              ))}
            </div>
            <Typography variant="h6" gutterBottom>היסטוריית גרסאות:</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>נוצר על ידי</TableCell>
                    <TableCell>נוצר ב</TableCell>
                    <TableCell>סטטוס</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allRevisions.map((revision, index) => (
                    <TableRow key={index}>
                      <TableCell>{revision.createdByUserName || 'לא ידוע'}</TableCell>
                      <TableCell>{formatDate(revision.createdAt)}</TableCell>
                      <TableCell>{index === 0 ? 'גרסה נוכחית' : ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', padding: '16px' }}>
            <Button onClick={onClose} variant="contained">
              אישור
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default ProtocolDetailsDialog;