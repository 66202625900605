import React from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, Typography 
} from '@mui/material';
import { primaryButtonStyle, secondaryButtonStyle, dialogActionsStyle } from '../utils/sharedStyles';

const ErrorDialog = ({ open, onClose, error }) => {
  const getErrorMessage = () => {
    if (error?.hebMessage) {
      return error.hebMessage;
    } else if (error?.message) {
      return error.message;
    } else if (error?.code === 401 || error?.code === 403) {
      return "אינך מורשה לבצע את הפעולה. אנא פנה לתמיכה של משלום, או למנהלים בבית החולים";
    } else if (error?.code === 404) {
      return "המשאב המבוקש לא נמצא. ייתכן והוא נמחק, או שאין לך הרשאות צפייה עליו.";
    } else {
      return "אירעה שגיאה בשרת. אם הבעיה נמשכת, אנא פנה לתמיכה של משלום - mashlom.me@gmail.com";
    }
  };

  const isRTL = error?.hebMessage || (!error?.message);

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '8px',
          padding: '16px',
        },
      }}
    >
      <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', color: '#103C6E' }}>
        שגיאה
      </DialogTitle>
      <DialogContent>
        <Typography 
          style={{ 
            direction: isRTL ? 'rtl' : 'ltr',
            textAlign: isRTL ? 'right' : 'left',
            marginBottom: '16px',
          }}
        >
          {getErrorMessage()}
        </Typography>
      </DialogContent>
      <DialogActions style={dialogActionsStyle}>
        <Button 
          onClick={onClose}
          style={primaryButtonStyle}
        >
          סגור
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;