
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

export const buttonStyle = {
  margin: theme.spacing(0, 1),
  padding: theme.spacing(0.75, 2),
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  fontWeight: 'bold',
};

export const primaryButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#103C6E',
  color: 'white',
  '&:hover': {
    backgroundColor: '#0c2d52',
  },
};

export const secondaryButtonStyle = {
  ...buttonStyle,
  backgroundColor: 'white',
  color: '#103C6E',
  border: '1px solid #103C6E',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
};

// You can also add other shared styles here
export const dialogActionsStyle = {
  justifyContent: 'center',
  padding: theme.spacing(2),
};