const environments = {
    development: {
      REACT_APP_ENV: 'development',
      REACT_APP_API_BASE_URL: ''
    },
    staging: {
      REACT_APP_ENV: 'staging',
      REACT_APP_API_BASE_URL: 'https://mashlom-stg-api-gyefcpeqa3cnejfx.westus-01.azurewebsites.net'
    },
    production: {
      REACT_APP_ENV: 'production',
      REACT_APP_API_BASE_URL: 'https://mashlom-prod-api-dwdvhvaxadbgfahv.westus-01.azurewebsites.net'
    }
  };
  
  // Get current environment from REACT_APP_ENV or default to 'development'
  const currentEnv = process.env.REACT_APP_ENV || 'development';
  
  // Ensure we have a valid environment, fallback to development if not
  const environment = environments[currentEnv] || environments.development;
  
  // Add the environment variables to window._env_
  window._env_ = environment;
  
  export default window._env_;